import React from "react"

const Footer = ({ siteTitle }) => (
  <footer style={{
    margin: `0 auto`,
    padding: `1.45rem 0`,
    backgroundColor: `#fff`,
    color: `#000`,
    fontSize: 12,
    textAlign: `center`
  }}>
    <sub>©</sub> {new Date().getFullYear()}, Built by
    {` `}
    <a href="https://www.petekang.dev">Pete Kang</a>
  </footer>
)

export default Footer
