import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroVideo from "../components/hero/heroVideo"
import Socials from "../components/socials/socials"
import About from "../components/about/about"
import Merch from '../components/merch/merch'
import New from "../components/new/new"
import Footer from "../components/footer"
import VideoComponent from "../components/videos/video"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroVideo />
    <New />
    <About />
    <VideoComponent />
    <Merch />
    <Socials />
    <Footer />
  </Layout>
)

export default IndexPage
