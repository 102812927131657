import React from "react"
import "./merch.css"

const Merch = () => (
  <section
    id="merch"
    style={{
      backgroundColor: `#fff`,
      padding: `6rem 20px`,
      overflow: `hidden`,
      color: `#000`,
    }}
  >
    <div className="about-small-section">
      <div className="about-description-container">
        <h1>Merch</h1>
      </div>
    </div>
    <div className="about-small-section merchandise-section">
      <div className="about-image-container">
        <a href="https://hypepins.com/products/summer-squirtle-hard-enamel-pin" target="_blank" alt="Turtle T Merchandise"><img src="https://i.imgur.com/BEcKDho.jpg" alt="Turtle T Merchandise" /></a>
      </div>
    </div>
    <div className="about-small-section merchandise-section">
      <div className="about-image-container">
        <a href="https://hypepins.com/products/sea-turtle-t-hard-enamel-pin" target="_blank" alt="Turtle T Merchandise"><img src="https://i.imgur.com/vD5hekT.jpg" alt="Turtle T Merchandise" /></a>
      </div>
    </div>
  </section>
)

export default Merch
