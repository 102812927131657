import React, { useState, useEffect } from "react"
import DesktopBG from "../../videos/turtle-promo.mp4"
import MobileBG from "../../videos/turtle-promo.mp4"
import Logo from "../logo"

let defaultWidth

if (typeof window !== `undefined`) {
  defaultWidth = window.innerWidth
}

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(defaultWidth)

  const handleWindowResize = () => {
    setWindowWidth(defaultWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return windowWidth
}

const HeroVideo = () => (
  <section
    style={{
      position: `relative`,
      height: `100vh`,
      width: `100%`,
      zIndex: `-1`,
      overflow: `hidden`,
    }}
  >
    <video
      autoPlay
      loop
      muted
      playsInline
      style={{
        position: `absolute`,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%,-50%)`,
        width: `auto`,
      }}
    >
      <source
        src={useWindowWidth() < 768 ? MobileBG : DesktopBG}
        type="video/mp4"
      />
    </video>
    <div 
      style={{
        position: `absolute`,
        left: `50%`,
        top: `50%`,
        transform: `translate(-50%,-50%)`,
        height: 150,
        width: 150,
      }}
    >
      <Logo />
    </div>
  </section>
)

export default HeroVideo
