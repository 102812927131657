import React from "react"
import "./new.css"

const New = () => (
  <section
    id="new"
    style={{
      backgroundColor: `#fff`,
      padding: `6rem 20px`,
      overflow: `hidden`,
      color: `#000`,
    }}
  >
    <div className="new-small-section">
      <div className="new-description-container">
        <h1>New Release</h1>
      </div>

      <div className="new-image-container">
          <a href="https://turtlet.bandcamp.com/track/view-from-my-window"><img src="https://i.imgur.com/GBLyltd.jpg" alt="View From My Window" /></a>
        
      </div>
    </div>
  </section>
)

export default New
