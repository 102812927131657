// import React, { useState, useEffect } from "react"
import React from "react"

import './video.css'

// let defaultWidth

// if (typeof window !== `undefined`) {
//   defaultWidth = window.innerWidth
// }

// const useWindowWidth = () => {
//   const [windowWidth, setWindowWidth] = useState(defaultWidth)

//   const handleWindowResize = () => {
//     setWindowWidth(defaultWidth)
//   }

//   useEffect(() => {
//     window.addEventListener("resize", handleWindowResize)

//     return () => window.removeEventListener("resize", handleWindowResize)
//   }, [])

//   return windowWidth
// }

const VideoComponent = () => (
  <section
    id="music"
    style={{
      backgroundColor: `#fff`,
      padding: `0 20px 5rem`,
      color: `#000`,
      textAlign: `center`,
    }}
  >
    <h2
      style={{
        marginBottom: `4rem`,
      }}
    >
      Music Video
    </h2>

    <div
      className="video-container"
      style={{
        width: `100%`,
        maxWidth: `1080px`,
        margin: `0 auto 4rem`,
      }}
    >
      <iframe
        src="https://www.youtube.com/embed/0YkoWeuG9y4"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen="true"
        style={{
          width: `100%`,
          height: `100%`,
          margin: 0,
        }}
      ></iframe>
    </div>
    <div
      className="video-container"
      style={{
        width: `100%`,
        maxWidth: `1080px`,
        margin: `0 auto`,
      }}
    >
      <iframe
        src="https://www.youtube.com/embed/Zt27PnVvNyo"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen="true"
        style={{
          width: `100%`,
          height: `100%`,
          margin: 0,
        }}
      ></iframe>
    </div>
  </section>
)

export default VideoComponent
