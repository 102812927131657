import React from "react"

// import EmailListForm from "../subscribe/subscribe"
import fbImage from "../../images/facebook.png"
import igImage from "../../images/instagram.png"
import twImage from "../../images/tw.png"
import spImage from "../../images/spotify.png"
import bcImage from "../../images/bc.png"

const SocialLinks = () => (
  <div
    style={{
      display: `flex`,
      justifyContent: `space-between`,
      margin: `auto`,
    }}
  >
    <a
      href="https://www.facebook.com/turtletmusic/"
      style={{
        textDecoration: `none`,
        cursor: `pointer`,
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          background: `url(${fbImage})`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `contain`,
          width: `40px`,
          height: `40px`,
        }}
      />
    </a>
    <a
      href="https://www.instagram.com/whatsupturtle/"
      style={{
        textDecoration: `none`,
        cursor: `pointer`,
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          background: `url(${igImage})`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `contain`,
          width: `40px`,
          height: `40px`,
        }}
      />
    </a>
    <a
      href="https://twitter.com/whatsupturtle"
      style={{
        textDecoration: `none`,
        cursor: `pointer`,
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          background: `url(${twImage})`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `contain`,
          width: `40px`,
          height: `40px`,
        }}
      />
    </a>
    <a
      href="https://open.spotify.com/artist/2niaeRAhjVFiLvMlP2a5Dd?si=7hGjbqLeTzadpQFzcbJWiw"
      style={{
        textDecoration: `none`,
        cursor: `pointer`,
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          background: `url(${spImage})`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `contain`,
          width: `40px`,
          height: `40px`,
        }}
      />
    </a>
    <a
      href="https://turtlet.bandcamp.com/"
      style={{
        textDecoration: `none`,
        cursor: `pointer`,
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          background: `url(${bcImage})`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `contain`,
          width: `40px`,
          height: `40px`,
        }}
      />
    </a>
  </div>
)

const Socials = () => (
  <section
    id="socials"
    style={{
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `center`,
      alignItems: `center`,
      backgroundImage: `url(https://i.imgur.com/S3IiDPu.jpg)`,
      backgroundPosition: `center center`,
      backgroundSize: `cover`,
      height: `100vh`,
      padding: `0 20px`,
      textAlign: `center`,
    }}
  >
    <div>
      <h1
        style={{
          marginBottom: 20,
        }}
      >
        Stay Connected
      </h1>
      <SocialLinks />
    </div>
  </section>
)

export default Socials
