import React from "react"
// import AboutBg from "./aboutBg"
import "./about.css"

const About = () => (
  <section
    id="about"
    style={{
      backgroundColor: `#fff`,
      padding: `0 20px 6rem`,
      overflow: `hidden`,
      color: `#000`,
    }}
  >
    <div className="about-small-section">
      <div className="about-image-container">
        <img src="https://i.imgur.com/V3Femqm.png" alt="Turtle T Pic" />
      </div>
      <div className="about-description-container">
        <h1>Turtle T</h1>
          <p>
            Known for his poetic rhyme style and balance of sophisticated humor,
            Seattle native MC, Turtle T aka Zac Millan, brings an authentic
            sound that’s as relatable as it is profound.
          </p>
      </div>
    </div>
  </section>
)

export default About
